
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import 'es6-promise/auto';
import 'babel-polyfill';
import datePicker from 'vue2-datepicker';
import veeno from 'veeno';
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import HighchartsVue from 'highcharts-vue';
import exportingInit from 'highcharts/modules/exporting';
import VueJsonToCsv from 'vue-json-to-csv'
import VueNumeric from 'vue-numeric'
import vSelect from 'vue-select'
import Editor from '@tinymce/tinymce-vue'
import uploader from 'vue-simple-uploader'
import { XlsCsvParser } from 'vue-xls-csv-parser';
import globalMixins from './mixins';
Vue.mixin(globalMixins);

Vue.component('date-picker', datePicker);
Vue.component('veeno-slider', veeno);
Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.component('v-select', vSelect);
Vue.component('v-editor', Editor);
Vue.component('xls-csv-parser', XlsCsvParser);


exportingInit(Highcharts);
More(Highcharts);

Highcharts.setOptions({
	lang: {
		thousandsSep: ','
	}
});

Vue.use(HighchartsVue, {tagName: 'highCharts'});
Vue.use(VueNumeric);
Vue.use(uploader);

// Vue.filter('formatDate', function (value) {
//     if (!value) return ''
//     return moment(value).format('DD/MM/YYYY');
// });

// import VueRouter from 'vue-router';
// import { routes } from './routes';

// Vue.use(VueRouter);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const router = new VueRouter({
//     mode: 'history',
//     routes
// });

// const app = new Vue({
//     el: '#app',
//     router,
//     /**
//      * Put User Collection with Role permission on initial.
//      */
//     // data: () => ({ user: user }),
//     /**
//      * Refresh Page when user click back button!
//      */
//     beforeCreate() {
//         if (window.history && window.history.pushState) {
//             $(window).on('popstate', function() {
//                 window.location.reload();
//             });
//         }
//     }
// });
