export default {
    filters: {
        /**
         *
         * 12 June 1979 => 1979-06-12
         *
         * @param {String} format YYYY-MM-DD
         */
        formatDate: function (value, format) {
            if (_.isEmpty(value)) return '-';
            if (!(value && format)) return value;
            return moment(value).format(format);
        },
        secondsToWords:function($seconds) {
            var $ret = "";

            /*** get the days ***/
            var $days = parseInt(parseInt($seconds) / (3600*24));
            if($days> 0)
            {
                $ret += $days + " days ";
            }

            /*** get the hours ***/
            var $hours = Math.floor((parseInt($seconds) / 3600) % 24);
            if($hours > 0)
            {
                $ret += ($hours < 10 ? "0" + $hours:$hours) + ":";
            } else {
                $ret += "00:";
            }

            /*** get the minutes ***/
            var $minutes = Math.floor((parseInt($seconds) / 60) % 60);
            if($minutes > 0)
            {
                $ret += ($minutes < 10 ? "0" + $minutes:$minutes) + ":";
            } else {
                $ret += "00:";
            }

            /*** get the seconds ***/
            var $seconds = Math.floor(parseInt($seconds) % 60);
            if ($seconds > 0) {
                $ret += ($seconds < 10 ? "0" + $seconds:$seconds);
            } else {
                $ret += "00";
            }

            return $ret;
        },
        /**
         *
         * 12345 => $12,345.00
         *
         * @param {String} symbol
         * @param {Number} decimals Decimal places
         * @param {Object} options
         */
        currency: function (value, symbol, decimals, options) {
            var thousandsSeparator, symbolOnLeft, spaceBetweenAmountAndSymbol
            var digitsRE = /(\d{3})(?=\d)/g
            options = options || {}
            value = parseFloat(value)
            if (!isFinite(value) || (!value && value !== 0)) return ''
            symbol = symbol != null ? symbol : '$'
            decimals = decimals != null ? decimals : 2
            thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ','
            symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true
            spaceBetweenAmountAndSymbol = options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false
            var stringified = Math.abs(value).toFixed(decimals)
            stringified = options.decimalSeparator
              ? stringified.replace('.', options.decimalSeparator)
              : stringified
            var _int = decimals
              ? stringified.slice(0, -1 - decimals)
              : stringified
            var i = _int.length % 3
            var head = i > 0
              ? (_int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : ''))
              : ''
            var _float = decimals
              ? stringified.slice(-1 - decimals)
              : ''
            symbol = spaceBetweenAmountAndSymbol
              ? (symbolOnLeft ? symbol + ' ' : ' ' + symbol)
              : symbol
            symbol = symbolOnLeft
              ? symbol + head +
                _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float
              : head +
                _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float + symbol
            var sign = value < 0 ? '-' : ''
            return sign + symbol
        },
        /**
         *  Converts a string into Capitalize
         *
         * 'abc' => 'Abc'
         */
        capitalize: function (value) {
            if (!value && value !== 0) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        /**
         * Converts a string to UPPERCASE
         *
         * 'abc' => 'ABC'
         */
        uppercase: function (value) {
            return (value || value === 0)
            ? value.toString().toUpperCase()
            : ''
        },
        /**
         * Converts a string to lowercase
         *
         * 'AbC' => 'abc'
         */
        lowercase: function (value) {
            return (value || value === 0)
            ? value.toString().toLowerCase()
            : ''
        },
        /**
         *  Truncate at the given || default length
         *
         * 'lorem ipsum dolor' => 'lorem ipsum dol...'
         */
        truncate: function (value, length) {
            length = length || 15
            if( !value || typeof value !== 'string' ) return ''
            if( value.length <= length) return value
            return value.substring(0, length) + '...'
        }
    },
    methods: {
        $setDateNull: function(obj) {
            if(obj && obj.doDate) {
                if(_.isEmpty(_.compact(obj.doDate))) obj.doDate = null;
            }
            return obj;
        },
        $setItemIds: function(event) {
            let checkBoxes = $(event.target).closest('table').find('td input[type="checkbox"]');
            if(event.target.value === 'all') { // Select all
                checkBoxes.prop('checked', $(event.target).prop('checked'));
            } else {
                let allCheckBox = $(event.target).closest('table').find('th input[type="checkbox"]');
                allCheckBox.prop('checked', !checkBoxes.not(':checked').length);
            }
            // Set Selected Items
            let selectedItemIds = []
            _.each(checkBoxes, function(ele){
                if($(ele).prop('checked')){
                    selectedItemIds.push($(ele).val());
                }
            });

            return selectedItemIds;
        },
        $sortByIndicator: function(filterData, field, isDelete = false, event) {
            return new Promise((resolve, reject) => {
                let self = this;
                let indexOf = _.indexOf(filterData, '-' + field) + 1  || _.indexOf(filterData, '+' + field) + 1;
                let sign = '+';
                let $ele = '';
                if($(event.target).hasClass('sortable')) {
                    $ele = $(event.target);
                } else {
                    $ele = $(event.target).parent();
                }
                $ele.removeClass('footable-sorted')
                .addClass('footable-sorted-desc')
                .addClass(field + '-sorted');
                if(indexOf === 0) {
                    filterData.push('+' + field);
                } else if(isDelete) {
                    let deleteClass = field + '-sorted';
                    let key = _.findIndex(filterData, function(o) {
                        return o.substring(1) == field;
                    });
                    filterData.splice(key, 1);
                    $('.' + deleteClass)
                    .removeClass('footable-sorted')
                    .removeClass('footable-sorted-desc')
                    .removeClass(deleteClass);
                } else {
                    sign = (filterData[(indexOf - 1)].substring(0, 1) === '+' ? '-':'+');
                    filterData[(indexOf - 1)] = sign + filterData[(indexOf - 1)].substr(1);
                    if(sign === '-') $(event.target).removeClass("footable-sorted-desc").addClass("footable-sorted");
                }
                resolve(filterData);
            });
        },
        $loadMore: function(hash, LoadMoreObj) {
            return new Promise((resolve, reject) => {
                let url = window.location.href;
                let loadMoreCount = LoadMoreObj.length;
                $.ajax({
                    url: url,
                    type: 'get',
                    data: {
                        'json': true,
                        'hash': hash,
                        'loadMoreCount': loadMoreCount
                    }
                }).done(function(response){
                    resolve(response);
                });
            });
        },
        $exportCsv: function(section_type, hash) {
            let base_url = '/en/ajax/exportCsv';
            let self = this;
            const urlParams = new URLSearchParams(window.location.search);
            let entries = urlParams.entries();
            let query_string;

            self.downloadingCSV = true;

            if(section_type === 'safety' && hash === 'driving') {
                query_string = '';
                for(let entry of entries) { // each 'entry' is a [key, value] tupple
                    const [key, value] = entry;
                    query_string += '&' + key + '=' + value;
                }
                const url = base_url + '?section_type=' + section_type + '&hash=' + hash + query_string;
                window.open(url);
                self.downloadingCSV = false;
                return;
            } else {
                query_string = {};
                for(let entry of entries) { // each 'entry' is a [key, value] tupple
                    const [key, value] = entry;
                    query_string = Object.assign(query_string, { [key]: value});
                }
            }

            $.ajax({
                url: base_url,
                type: 'post',
                data: {
                    section_type: section_type,
                    hash: hash,
                    params: query_string
                }
            }).done(function(response){
                let csvContent = "";
                let csvContentHeader = "";

                $.each(response, function(key, obj){
                    $.each(obj, function(index, item){
                        if(key === 0) { // Set Header
                            csvContentHeader += index + ","
                        }

                        csvContent += item + ","
                    })

                    if(key === 0) {
                        csvContentHeader = csvContentHeader.slice(0,-1) + "\n";
                    } else {
                        csvContentHeader = "";
                    }

                    csvContent = csvContentHeader + csvContent.slice(0,-1) + "\n";
                });


                var universalBOM = "\uFEFF";
                var hiddenElement = document.createElement('a');
                if(!_.isEmpty(csvContent)) {
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + csvContent);
                } else {
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
                }
                hiddenElement.target = '_blank';
                hiddenElement.download = section_type + '-' + hash + '.csv';
                hiddenElement.click();

                self.downloadingCSV = false;
            }).catch(function(err) {
                self.downloadingCSV = false;
            });
        },
        $exportExcel: function(date_start, date_end, category, optional = {}) {
            let obj = {};
            if(date_start !== null && date_end !== null) {
                let fromDate = moment(date_start).format('YYYY-MM-DD');
                let toDate = moment(date_end).format('YYYY-MM-DD');
                obj.date_range = fromDate + ',' + toDate;
            }
            _.each(optional, function(index, value){
                obj[index] = value;
            });

            window.location.href =  '/en/setting/' + category + '/exports?' + $.param(obj);
        }
    }
};
